import React, { useState, useRef, useEffect } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Select,
  Button,
  Field,
  Control,
  StaticButton,
  SpanVerticalAlign,
} from "../../../StyleComponents/styles"
import { FlexDiv, CheckBox } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Generator", link: "/generator/" },
  {
    name: "Password Generator",
    link: "/password-generator/",
  },
]
const seeAlsoArray = [
  "/font-generator",
  "/number-generator",
  "/flip-a-coin",
  "/quote-generator",
]

function PasswordGenerator(props) {
  let [pwLength, setpwLength] = useState("16")
  let [passwordResult, setpasswordResult] = useState("")
  let [copyBtn, setcopyBtn] = useState("Copy")
  let passwordToCopy = useRef("")

  // let [listValue, setlistValue] = useState("")
  // let [listResult, setlistResult] = useState([])
  // const updateListValue = e => {
  //   setlistValue(e.target.value)
  // }
  // const generateListPass = () => {
  //   let finalResult = []
  //   let result = []
  //   let array = listValue.split(",")
  //   let ar = getAllSubsets(array)
  //   for (const a of ar) {
  //     if (a.length !== 0) {
  //       if (a.length > 1) {
  //         result.push(combo(a))
  //       } else {
  //         result.push(a)
  //       }
  //     }
  //   }
  //   // to remove space
  //   // .trim()
  //   // let lastResult = [...new Set([].concat(...result.map(o => o)))]
  //   let joinArrayInsideArray = result.flat()
  //   for (const b of joinArrayInsideArray) {
  //     Array.isArray(b) ? finalResult.push(b.join()) : finalResult.push(b)
  //   }
  //   setlistResult(finalResult)
  // }

  // const combo = c => {
  //   let r = [],
  //     len = c.length,
  //     tmp = []
  //   function nodup() {
  //     let got = {}
  //     for (let l = 0; l < tmp.length; l++) {
  //       if (got[tmp[l]]) return false
  //       got[tmp[l]] = true
  //     }
  //     return true
  //   }
  //   function iter(col, done) {
  //     let l, rr
  //     if (col === len) {
  //       if (nodup()) {
  //         rr = []
  //         for (l = 0; l < tmp.length; l++) rr.push(c[tmp[l]])
  //         r.push(rr)
  //       }
  //     } else {
  //       for (l = 0; l < len; l++) {
  //         tmp[col] = l
  //         iter(col + 1)
  //       }
  //     }
  //   }
  //   iter(0)
  //   return r
  // }

  // const getAllSubsets = theArray =>
  //   theArray.reduce(
  //     (subsets, value) => subsets.concat(subsets.map(set => [value, ...set])),
  //     [[]]
  //   )

  function pwLengthC(e) {
    setpwLength(e.target.value)
  }
  useEffect(() => {
    generatePass()
  }, [])

  function generatePass() {
    let newPassword = ""
    let chars = ""
    let caps = document.getElementById("caps")
    let special = document.getElementById("special")
    let myspecial = document.getElementById("myspecial")
    let numbers = document.getElementById("numbers")
    let mytoospecial = document.getElementById("mytoospecial")
    if (caps.checked) {
      chars = chars.concat("ABCDEFGHIJKLMNOPQRSTUVWXYZ")
    }
    if (mytoospecial.checked) {
      chars = chars.concat("abcdefghijklmnopqrstuvwxyz")
    }
    if (special.checked) {
      chars = chars.concat("!@#$%&?*_")
    }
    if (myspecial.checked) {
      chars = chars.concat("^()|}{[]:;></")
    }
    if (numbers.checked) {
      chars = chars.concat("0123456789")
    }
    for (let i = pwLength; i > 0; --i) {
      newPassword += chars[Math.round(Math.random() * (chars.length - 1))]
    }
    setpasswordResult(newPassword)
  }

  function copyFunction() {
    passwordToCopy.current.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setcopyBtn("Copied 😀")
      setTimeout(function () {
        setcopyBtn("Copy")
      }, 1500)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Random Password Generator - Generate random password"
        description="Random Password Generator, Generate random password, Simple choose your option and make your strong password online, Your option: capital letters or small letters, numbers, and symbols. create a strong password, make your strong password."
        keywords={[
          "random password generator, generate password, random password, make password, make strong password, password generator online, random password online, make password online, generate strong password online, make password symbols, truly random password, create random password,create password online, create symbols password, generate long password,long password.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title>Password Generator</Title>
        <SubTitle>Random Password Generator</SubTitle>
        <FlexDiv maxWidth="500px">
          <Input
            type="text"
            value={passwordResult}
            ref={passwordToCopy}
            readOnly
            placeholder="Generate Password"
          />

          <Field>
            <Control>
              <StaticButton>Password Length</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Password-Length"
                  value={pwLength}
                  onChange={pwLengthC}
                  onBlur={pwLengthC}
                >
                  <optgroup label="Weak">
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </optgroup>

                  <optgroup label="Strong">
                    <option value="16">16</option>
                    <option value="24">24</option>
                  </optgroup>

                  <optgroup label="Very Strong">
                    <option value="32">32</option>
                    <option value="64">64</option>
                    <option value="128">128</option>
                    <option value="256">256</option>
                  </optgroup>

                  <optgroup label="Unbelievable">
                    <option value="512">512</option>
                    <option value="1024">1024</option>
                    <option value="2048">2048</option>
                  </optgroup>
                </select>
              </Select>
            </Control>
          </Field>

          <FlexDiv displayDiv="inline-flex" flexWrap="wrap">
            <div className="mr-2 mb-2">
              <CheckBox
                aria-label="caps"
                type="checkbox"
                id="caps"
                defaultChecked="checked"
                borderColor="#1da1f2"
                color="#1da1f2"
                backgroundColor="#1da1f2"
              />
              <SpanVerticalAlign verticalAlign="super"> A-Z</SpanVerticalAlign>
            </div>

            <div className="mr-2 mb-2">
              <CheckBox
                aria-label="mytoospecial"
                type="checkbox"
                id="mytoospecial"
                defaultChecked="checked"
                borderColor="#1da1f2"
                color="#1da1f2"
                backgroundColor="#1da1f2"
              />
              <SpanVerticalAlign verticalAlign="super"> a-z</SpanVerticalAlign>
            </div>

            <div className="mr-2 mb-2">
              <CheckBox
                aria-label="numbers"
                type="checkbox"
                id="numbers"
                defaultChecked="checked"
                borderColor="#1da1f2"
                color="#1da1f2"
                backgroundColor="#1da1f2"
              />
              <SpanVerticalAlign verticalAlign="super"> 1-9</SpanVerticalAlign>
            </div>
            <div className="mr-2 mb-2">
              <CheckBox
                aria-label="special"
                type="checkbox"
                id="special"
                defaultChecked="checked"
                borderColor="#1da1f2"
                color="#1da1f2"
                backgroundColor="#1da1f2"
              />
              <SpanVerticalAlign verticalAlign="super">
                {" "}
                !@#-?$%
              </SpanVerticalAlign>
            </div>
            <div className="mr-2 mb-2">
              <CheckBox
                aria-label="myspecial"
                type="checkbox"
                id="myspecial"
                borderColor="#1da1f2"
                color="#1da1f2"
                backgroundColor="#1da1f2"
              />
              <SpanVerticalAlign verticalAlign="super">
                {" "}
                &lt; |;:^[
              </SpanVerticalAlign>
            </div>
          </FlexDiv>
        </FlexDiv>

        <div>
          <Button
            type="button"
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={generatePass}
          >
            Generate
          </Button>
          <Button
            type="button"
            className="mb-2"
            borderColor="#F55A34"
            color="#F55A34"
            hoverColor="white"
            hoverBorderColor="#F55A34"
            hoverBackgroundColor="#F55A34"
            onClick={copyFunction}
          >
            {copyBtn}
          </Button>
        </div>
        <br />
        <FlexDiv maxWidth="250px">
          <SmallImg
            filename="password.png"
            alt="random strong password generator"
          />
        </FlexDiv>

        {/* <br />
      <section>
        <h1>generate words password all Probability</h1>
        <label>
          <input
            type="text"
            className="input"
            value={listValue}
            onChange={updateListValue}
          />
        </label>
        <button className="button" onClick={generateListPass}>
          Make List
        </button>
        {listResult
          ? listResult.map((e, index) => {
              console.log(e)
              return (
                <p key={index}>{e.split(",").join("").replace(/\s/g, "")}</p>
              )
            })
          : ""}*/}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default PasswordGenerator
